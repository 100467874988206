export type FetchErrorDetails = {
  url: string;
  status?: number;
  body?: string;
};

export class FetchError extends Error {
  static async fromResponse(res: Response) {
    const { url, status } = res;
    const body = await res.text();
    const isJsonResponse = res.headers.get('Content-Type')?.includes('application/json');
    const bodyInMessage = !isJsonResponse ? body.slice(0, 100) : '';
    const message = [res.status, res.statusText, bodyInMessage].filter(Boolean).join(' ');

    return new FetchError(message, { url, status, body });
  }

  static fromUnknownError(e: unknown, url = '') {
    if (e instanceof FetchError) return e;

    const message = e instanceof Error ? e.message : String(e);
    const error = new FetchError(message, { url });
    error.stack = e instanceof Error ? e.stack : '';

    return error;
  }

  constructor(
    message: string,
    public details: FetchErrorDetails,
  ) {
    super(message);
  }

  get url() {
    return this.details.url;
  }

  get status() {
    return this.details.status || 0;
  }
}
