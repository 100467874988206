import { ReactNode } from 'react';
import { Button } from '@/components/common/button/Button';
import classNames from 'classnames';

// a "standard" (from a certain moment) modal with (possibly) icon, header, body and buttons list

type UniModalButtonProps = {
  primary?: boolean;
  disabled?: boolean;
  caption: string;
  value: string;
};

export type UniModalProps = {
  icon?: ReactNode;
  header: ReactNode;
  body: ReactNode;
  buttons?: UniModalButtonProps[];
  onClose?: (value?: string) => void;
};

export function UniModal(props: UniModalProps) {
  const buttons = props.buttons || [{ primary: true, caption: 'OK', value: 'ok' }];
  const hasIcon = !!props.icon;
  return (
    <div className="bg-white w-[512px] p-8 pt-12">
      <div className="flex flex-col text-darkGrey-600">
        {hasIcon && <div className="text-center mb-5">{props.icon}</div>}
        <h2 className={classNames('text-2xl font-medium mb-4', hasIcon ? 'text-center' : '')}>
          {props.header}
        </h2>
        {!hasIcon && <hr className="mb-3" />}
        <div className={classNames('pt-3', hasIcon ? 'text-center pb-8' : '')}>{props.body}</div>
        <div className="flex flex-col mt-8 gap-5">
          {buttons?.map((button, index) => (
            <Button
              key={index}
              autoFocus={button.primary}
              secondary={!button.primary}
              disabled={button.disabled}
              onClick={() => {
                props.onClose?.(button.value);
              }}
            >
              {button.caption}
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
}
