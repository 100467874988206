import { getEnvVar } from './utils/env';

/**
 * See: https://nextjs.org/docs/basic-features/environment-variables
 */
export const env = {
  name: getEnvName(),
  isProduction: getEnvName() === 'production',
  isLocal: getEnvName() === 'local',
  marketplaceHost: getEnvVar(() => process.env.NEXT_PUBLIC_MARKETPLACE_HOST),
  build: {
    time: process.env.NEXT_PUBLIC_BUILD_TIME || '',
    sha: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA || '',
    version: process.env.APP_VERSION || 'unknown',
  },
  aad: {
    tenantId: getEnvVar(() => process.env.AZURE_AD_TENANT_ID),
    clientId: getEnvVar(() => process.env.AZURE_AD_CLIENT_ID),
    clientSecret: getEnvVar(() => process.env.AZURE_AD_CLIENT_SECRET),
  },
  algolia: {
    appId: getEnvVar(() => process.env.NEXT_PUBLIC_ALGOLIA_APP_ID),
    neuralSearchAppId: getEnvVar(() => process.env.NEXT_PUBLIC_ALGOLIA_NEURAL_SEARCH_APP_ID),
    // ALGOLIA_API_KEY and NEURAL_SEARCH_ALGOLIA_API_KEY must be without NEXT_PUBLIC prefix as we use it on backend only
    apiKey: getEnvVar(() => process.env.ALGOLIA_API_KEY),
    neuralSearchApiKey: getEnvVar(() => process.env.NEURAL_SEARCH_ALGOLIA_API_KEY),
    poIndexName: getEnvVar(() => process.env.NEXT_PUBLIC_ALGOLIA_STATUS_INDEX_PO),
    prIndexName: getEnvVar(() => process.env.NEXT_PUBLIC_ALGOLIA_STATUS_INDEX_PR),
    ivtIndexName: getEnvVar(() => process.env.NEXT_PUBLIC_ALGOLIA_STATUS_INDEX_IVT),
    crIndexName: getEnvVar(() => process.env.NEXT_PUBLIC_ALGOLIA_STATUS_INDEX_CR),
    suppliersIndexName: getEnvVar(() => process.env.NEXT_PUBLIC_ALGOLIA_SUPPLIERS_INDEX),
    aribaUserIndexName: getEnvVar(() => process.env.NEXT_PUBLIC_ALGOLIA_ARIBAUSER_INDEX),
    subcategoryIndexName: getEnvVar(() => process.env.NEXT_PUBLIC_ALGOLIA_SUBCATEGORY_INDEX),
  },
  appInsights: {
    connectionString: getEnvVar(() => process.env.NEXT_PUBLIC_APP_INSIGHTS_CONNECTION_STRING),
  },
  azureFn: {
    api: getEnvVar(() => process.env.NEXT_PUBLIC_AZURE_FN_API),
  },
  suppliersApi: {
    api: getEnvVar(() => process.env.SUPPLIER_API),
    key: getEnvVar(() => process.env.SUPPLIER_API_KEY),
  },
  configcat: {
    configcatKey: getEnvVar(() => process.env.NEXT_PUBLIC_CONFIGCAT_KEY),
  },
  sanity: {
    projectId: getEnvVar(() => process.env.SANITY_PROJECT_ID),
    dataset: getEnvVar(() => process.env.SANITY_DATASET),
    token: getEnvVar(() => process.env.SANITY_TOKEN),
  },
  datadog: {
    applicationId: getEnvVar(() => process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID),
    clientToken: getEnvVar(() => process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN),
  },
  ownApi: {
    secret: getEnvVar(() => process.env.OWN_API_SECRET),
  },
  testUserPassword: getEnvVar(() => process.env.TEST_USER_PASSWORD),
};

/**
 * See: https://vercel.com/docs/projects/environment-variables/system-environment-variables
 */
function getEnvName() {
  switch (process.env.NEXT_PUBLIC_VERCEL_ENV) {
    case 'production':
      return 'production';
    case 'preview':
      return process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF === 'staging' ? 'staging' : 'dev';
    case 'development':
    default:
      return 'local';
  }
}
